body {
	margin: 0;
}

.spec-select {
	font-family: Roboto, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5em;
	position: fixed;
	top: 8px;
	right: 8px;
	z-index: 2;
	padding: 8px;
	border: 1px solid;
	background: #fff;
	border-radius: 0.25rem;
	box-shadow: none;
}

.menu-content > div:first-child > img {
	width: 80px;
	height: auto;
	display: block;
	margin: 20px auto;
}
